import React from "react"
import PropTypes from "prop-types"
import Clamp from "components/clamp"
import * as styles from "./hero.module.less"

export default function Hero({ children }) {
  return (
    <div className={styles.root}>
      <div className={styles.deck}>
        <Clamp>{children}</Clamp>
      </div>
    </div>
  )
}

Hero.propTypes = {
  children: PropTypes.node,
}
