import React from "react"
import Clamp from "components/clamp"
import Hero from "components/hero"

export default () => (
  <>
    <Hero>Press release</Hero>
    <Clamp inset>
      <h1>GPI Capital Appoints Philip Lo as Head of Investor Relations</h1>
      <p>
        New York, February 12, 2020 – GPI Capital (“GPI”), a leading alternative
        investment firm focused on non-control investing in growth-oriented
        technology, consumer and industrial companies, announced that Philip Lo
        has joined as Managing Director, Investor Relations and Business
        Development. In his role, Mr. Lo will lead the firm’s capital raising
        function and manage relationships with new and existing limited
        partners.
      </p>
      <p>
        Mr. Lo was most recently a Managing Director, Investor Relations, at
        Siris Capital, where he was part of the original team and led the firm’s
        capital raising efforts. During his tenure, he helped raise three
        platform funds of increasing size, with aggregate commitments of fund
        and co-investment capital of over $9 billion. His firsthand experience
        at growing a leading technology-oriented private equity firm is a
        natural fit for GPI.
      </p>
      <p>
        Bill Royan, Managing Partner, commented "Philip has a tremendous wealth
        of experience across all aspects of the alternatives business and we are
        delighted to welcome him to the GPI family. He brings deep relationships
        with limited partners across the globe and his entrepreneurial drive
        squarely fits our culture as we continue to invest in talent and
        position the firm for further growth."
      </p>
      <p>
        Mr. Lo brings over 12 years of alternative investment expertise to GPI.
        He started his career in investment banking with Deutsche Bank
        Securities and Houlihan Lokey. Mr. Lo holds a Bachelor of Science in
        Business Administration degree from Georgetown University.
      </p>
      <h2>About GPI Capital</h2>
      <p>
        GPI Capital is a leading alternative investment firm that provides
        strategic capital and partners with growth-oriented technology, consumer
        and industrial companies, primarily in North America and Western Europe.
        GPI is focused on making non-control structured and growth equity
        investments targeting $50 million to $300 million per transaction. GPI
        serves as a value-add partner and its customized capital solutions
        enable portfolio companies to fund transformational growth, including
        M&amp;A and shareholder transitions.
      </p>
    </Clamp>
  </>
)
